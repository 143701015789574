import React from 'react';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">zombi.dev</a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>We're just finishing some things up. Please check back to know more. You can access some quick links below.</p>
          </div>
          <a href="./quick-links">
            <div className="cta">Look at some quick links!</div>
          </a>
          <iframe 
            src="https://zombidev.instatus.com/embed-status/b33d538d/dark-md" 
            width="230"
            height="61" 
            frameBorder="0" 
            scrolling="no" 
            style="border: none;"
          ></iframe>
        </div>
        <div className="footer">
          <span>made using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">ReactJS</a></span>
        </div>
      </div>
    );
  }
}

export default App;